import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from "next-auth/react"
import { EnvContextProvider } from '@/components/contexts/envContext'

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <SessionProvider session={session} refetchOnWindowFocus>
      <EnvContextProvider>
        <Component {...pageProps} />
      </EnvContextProvider>
    </SessionProvider>
  )
}
