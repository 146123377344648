
import { ReactNode, createContext } from "react";
import { IEnvSetting, useEnvSetting } from "../hooks/useEnvSetting";


export const EnvContext = createContext<IEnvSetting | null>(null);

export function EnvContextProvider(props: { children: ReactNode }) {
  const { children } = props;
  const envSetting = useEnvSetting();
  return <EnvContext.Provider value={envSetting}>{children}</EnvContext.Provider>
}