import { useEffect, useState } from "react";

export interface IEnvSetting {
  PUBLIC_API_URL: string;
}

async function getEnvSetting(callback: (val: IEnvSetting) => void) {
  const res: IEnvSetting = await (await fetch('/api/envsettings/public')).json();
  console.log(res);
  callback(res);
}

export function useEnvSetting() {
  const [settings, setSettings] = useState<IEnvSetting | null>(null);
  useEffect(() => {
    getEnvSetting(setSettings);
  }, [])
  return settings;
}